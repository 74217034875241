<template>
  <div>
    <div v-if="loading" class="loading-container pt-12">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </div>
    <v-form
      @submit.prevent="save"
      ref="form"
      v-show="!loading"
      :disabled="$attrs.disabled"
      class="max-width"
    >
      <ErrorMessages :messages="errors"></ErrorMessages>
      <v-row>
        <v-col>
          <v-select
            label="Fila de Trabalho"
            :items="lists"
            v-model="form.list_isn"
            item-value="lst_isn"
            item-text="lst_nome"
            :rules="$rules.required"
            required
            dense
            outlined
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-checkbox
            v-model="form.colored_font"
            label="Fonte com a cor do indicador"
            color="primary"
            class="mt-0 mb-2"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-tabs class="nj-tabs" v-model="tab">
            <v-tab>Colunas Visíveis</v-tab>
            <v-tab>Ordem dos registros</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" eager>
            <v-tab-item class="py-4" eager>
              <v-data-table
                v-model="form.fields"
                :headers="headers"
                :items="visibleFields"
                :single-select="false"
                item-key="name"
                show-select
                class="elevation-1"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:item.controls="{ item, index }">
                  <v-btn
                    icon
                    color="primary"
                    :disabled="$attrs.disabled"
                    @click="moveUp(visibleFields, index)"
                  >
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="primary"
                    :disabled="$attrs.disabled"
                    @click="moveDown(visibleFields, index)"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item class="py-4" eager>
              <v-data-table
                v-model="form.orders"
                :headers="headers"
                :items="orderItems"
                :single-select="false"
                item-key="value"
                show-select
                class="elevation-1"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:item.controls="{ item, index }">
                  <v-btn icon color="primary" @click="moveUp(orderItems, index)" :disabled="$attrs.disabled">
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                  <v-btn icon color="primary" @click="moveDown(orderItems, index)" :disabled="$attrs.disabled">
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.column="{ item }">
                  {{ item.text }}
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>

      <slot></slot>
    </v-form>
  </div>
</template>
<style scoped>
.max-width {
  max-width: 600px;
}
</style>
<script>
import Api from '@/resources/NajaSocialApi'
import ErrorMessages from '@/components/ErrorMessages'
import MedicalReportSettingEnum from '@/enumerations/medical_report_settings_enum'

export default {
  name: 'MedicalReportSettingForm',
  components: { ErrorMessages },
  data: () => ({
    saveLoading: false,
    visibleFieldsItems: [],
    orderItems: MedicalReportSettingEnum.orders,
    headers: [
      { text: '', value: 'controls', sortable: false },
      { text: 'Coluna', value: 'human_name' },
    ],
    visibleFields: [],
    loading: false,
    errors: [],
    lists: [],
    outboxes: [],
    tab: 0,
    form: {
      list_isn: null,
      colored_font: false,
      fields: [],
      orders: [],
    },
  }),

  filters: {
    fieldText: function (value) {
      return (
        MedicalReportSettingEnum.fields.find(x => x.value === value)?.text || ''
      )
    },
  },

  mounted() {
    this.fetch(this.$route.params.id)
  },

  methods: {
    _combineFields(source, persisted) {
      const combinedArray = []

      persisted.forEach(item2 => {
        const match = source.find(item1 => item1.name === item2.name)
        if (match) {
          match.selected = item2.selected;
          combinedArray.push(match);
        }
      })
      
      source.forEach(item1 => {
        const match = persisted.find(item2 => item2.name === item1.name)
        if (!match) {
          combinedArray.push(item1);
        }
      })
    },

    moveUp(items, index) {
      if (index > 0) {
        items.move(index, index - 1)
      }
    },

    moveDown(items, index) {
      const limit = items.length - 1
      if (index < limit) {
        items.move(index, index + 1)
      }
    },

    remove() {
      if (!this.$hasPermission('naja_app_medical_report_settings_destroy')) {
        alert('Acesso negado')
        return
      }

      this.$refs.removeQuestionDialog.open({
        onConfirm: () => {
          this.$refs.removeQuestionDialog.toggleLoading()
          Api.medicalReportSettings
            .destroy(this.$route.params.id)
            .then(async () => {
              this.$router.push({ name: 'MedicalReportSettings' })
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 500) {
                  this.$root.$children[0].toast(
                    'Erro ao remover configuração de fila de trabalho',
                    'error'
                  )
                } else {
                  this.$root.$children[0].toast(
                    error.response.data.error,
                    'error'
                  )
                }
              } else {
                throw error
              }
            })
            .finally(() => {
              this.$refs.removeQuestionDialog.close()
            })
        },
      })
    },

    _parseForm(data) {
      const fieldsOrder = data.fields_order || []
      const selectedOrders = fieldsOrder
        .filter(x => x.selected)
        .map(x => x.name)
      const formPayload = {
        id: data.id,
        list_isn: String(data.list_isn),
        colored_font: data.colored_font,
        orders: MedicalReportSettingEnum.orders.filter(x =>
          selectedOrders.includes(x.value)
        ),
        fields: data.visible_fields.filter(x => x.selected),
      }

      // order
      const orderMap = new Map();
      data.visible_fields.forEach((item, index) => orderMap.set(item.name, index));
      this.visibleFields.sort((a, b) => orderMap.get(a.name) - orderMap.get(b.name));


      this.form = { ...this.form, ...formPayload }
    },

    async fetch(id = null) {
      try {
        this.loading = true

        const listsResult = await Api.medicalReportSettings.lists()
        this.lists = listsResult.data.lists

        const visibleFieldsResult = await Api.medicalReportSettings.visibleFields()
        this.visibleFields = visibleFieldsResult.data.visible_fields

        if (id === null) {
          return
        }

        const response = await Api.medicalReportSettings.fetch(id)
        this._parseForm(response.data.medical_report_setting)
      } catch (e) {
        this.$root.$children[0].toast(
          'Erro ao buscar Configuração de Fila de Trabalho'
        )
        throw e
      } finally {
        this.$scrollTop()
        this.loading = false
      }
    },

    save() {
      this.saveLoading = true

      const payload = {
        medical_report_setting: {
          list_isn: this.form.list_isn,
          colored_font: this.form.colored_font,
          visible_fields: this.visibleFields.map(item => ({
            name: item.name,
            selected: this.form.fields.map(x => x.name).includes(item.name),
          })),

          fields_order: this.orderItems.map(item => ({
            name: item.value,
            selected: this.form.orders.map(x => x.value).includes(item.value),
          })),
        },
      }

      Api.medicalReportSettings
        .save(this.$route.params.id, payload)
        .then(response => {
          this._parseForm(response.data.medical_report_setting)
          this.$root.$children[0].toast(
            'Configuração de Fila de Trabalho salva com sucesso'
          )

          this.$router.push({
            name: 'MedicalReportSettingShow',
            params: { id: this.form.id },
          })
        })
        .catch(error => {
          if (error.response) {
            this.$root.$children[0].toast(
              'Erro ao salvar Configuração de Fila de Trabalho'
            )
            if (error.response.status != 500) {
              this.errors = error.response.data.errors

              this.$refs.form.validate()
            }
          } else {
            throw error
          }

          // throw error;
        })
        .finally(() => {
          this.$scrollTop()
          this.saveLoading = false
        })
    },
  },
}
</script>
