<template>
  <v-alert v-show="errorMessages.length > 0" dense outlined type="error">
    <ul>
      <li v-for="(error, index) in errorMessages" :key="index">{{ error }}</li>
    </ul>
  </v-alert>
</template>
<script>
export default {
  name: 'ErrorMessages',
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    messages(newValue) {
      this.errorMessages = newValue
    },
  },
  data() {
    return {
      errorMessages: this.messages,
    }
  },
}
</script>
